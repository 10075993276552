<script>
    import Orders from '@/services/Orders';
    import Swal from "sweetalert2";

    export default {
        props: ['guid','order_type','legacy'],
        data() {
            return {
                showModal: false,
                orderDetails:[],
                statuses:[],
                tryingToEdit: false,
                submitted: false,
                showLoader:false
            };
        },
        created() {
            this.getStatuses()
        },
        methods: {

            async updateOrder(){

                this.tryingToEdit = true;
                this.submitted = true;

                try {
                    await Orders.updateOrder({
                        order_guid: this.guid,
                        order:{
                            status : this.orderDetails.data.status
                        },

                    }).then((response) => {
                        this.successmsg(response);
                        this.closeModal();
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }).finally(() => {
                        this.refreshData()
                        this.tryingToEdit = false;
                    })
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }
            },

            async getOrderDetails(){
                this.showLoader = true;
                if(this.legacy == 1){
                   await Orders.getOrderLegacy(this.guid, this.order_type).then((response) => {
                       this.orderDetails = response.data;
                   }).catch(error => {
                       this.error = error.response.data.error ? error.response.data.error : "";
                       this.failedmsg(this.error)
                   }).finally(() => {
                       this.showLoader = false;
                   })

               } else {
                   await Orders.getOrder(this.guid).then((response) => {
                       this.orderDetails = response.data;
                   }).catch(error => {
                       this.error = error.response.data.error ? error.response.data.error : "";
                       this.failedmsg(this.error)
                   }).finally(() => {
                       this.showLoader = false;
                   })
               }
            },

            async getStatuses(){
                await Orders.getStatuses().then((response) => {
                    this.statuses = response.data.data;
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }).finally(() => {
                })
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },
        }
    };
</script>

<template>
    <b-modal @shown="getOrderDetails" v-model="showModal" id="edit_order" title="Edit Customer Order" title-class="font-18" size="xl" centered>
        <template v-if="!showLoader">
            <div class="row mb-3" v-if="orderDetails.data">
                <div class="col-sm-6">
                    <b-form-group label="Status" label-for="formrow-status-input" class="mb-3">
                        <select v-model="orderDetails.data.status" name="statuses" id="statuses" class="form-select">
                            <option v-for="status in statuses" :key="status" :value="status">{{status}}</option>
                        </select>
                    </b-form-group>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
                <b-spinner large></b-spinner>
            </div>
        </template>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="updateOrder" :disabled="tryingToEdit || showLoader">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change</b-button>
        </template>
    </b-modal>
</template>